import React from 'react';
import { Box, Container, makeStyles, Button } from "@material-ui/core";
import AuthLayout from "./AuthLayout"

let headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Expose-Headers": ["access-token", "expiry", "token-type", "uid", "client"],
}
const DisableSignUpHrNotificationScreen = (props) => {
  const classes = useStyles()

  const requestAccount = (e) => {
    if (!props.hr_detail.request_url) return
    e.preventDefault();
    $.ajax({
      method: 'POST',
      url: props.hr_detail.request_url,
      headers: headers,
      success: (response) => {
        toastr.success(response.message)
      },
      error: (err) => {
        toastr.error(JSON.parse(err.responseText).message)
      }
    })
  }

  return (
    <AuthLayout isConfidentialPlanning={props.is_confidential_planning}>
      <Container maxWidth="sm">
        <div className={classes.registrationDiv}>
          <div className={classes.headings}>
            <span className={classes.heading1}>
              {props.hr_detail.title ? <span className="underline">{props.hr_detail.title}</span> : ''}
            </span>
          </div>
          <Box>
            <div className={classes.headings}>
              <span className={classes.heading1}>
                {props.hr_detail.message}
              </span>
            </div>
            <div className={classes.formGroup}>
              <Button style={{ fontFamily: "MontserratRegular" }} className="primary-login-button" type="submit" onClick={requestAccount}>
                Request Account
              </Button>
            </div>
          </Box>
        </div>
      </Container>
    </AuthLayout>
  )
}

const useStyles = makeStyles({
  headings: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 70,
    marginBottom: 30,
  },
  formDiv: {
    marginTop: 40,
    display: "flex",
    flexDirection: "column",
  },
  registrationDiv: {
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 10px)'
  },
  heading1: {
    fontSize: 32,
    fontFamily: "MontserratExtraBold",
  }
});

export default DisableSignUpHrNotificationScreen;
