import React, { useEffect, useState } from 'react';
import { TableContainer, TableHead, TableBody, TableRow, TableCell, Table, Paper, styled, makeStyles, TableSortLabel, Box, CircularProgress, Button } from '@material-ui/core'
import Pagination from '../pagination/pagination';
import ModalBox from './ModalBox';
import CustomContentHandler from './CustomContentHandler';
import CustomOverridesHandler from './CustomOverridesHandler';
import CustomRulesHandler from './CustomRulesHandler';

const ACTION_NAME = {
  "LEAVE_BENEFIT": 'leave_benefits',
  "PLANNING_GUIDE": 'planning_guides',
  "POLICY_ITEMS": 'policy_items',
  "MANAGER_TRAINING": 'manager_training_items',
}

const COMPANY_RECORD_HEADER_NAME = {
  'leave_benefits': 'Company Benefit Updated',
  'policy_items': 'Company Task Item Updated',
  'planning_guides': 'Company Planning Guide Updated',
  'manager_training_items': 'Company Manager Training Updated',
}

const TEMPLATE_RECORD_HEADER_NAME = {
  'leave_benefits': 'Benefit Template Updated',
  'policy_items': 'Task Item Template Updated',
  'planning_guides': 'Planning Guide Template Updated',
  'manager_training_items': 'Manager Training Template Updated',
}

const READABLE_STATUS = {
  retired: 'Retired',
  active: 'Active',
  in_review: 'In Review',
  draft: 'Draft',
  removed: 'Removed'
}

export default function CompanyCustomContentTable (props) {
  const [pageNumber, setPageNumber] = useState('1')
  const [totalCount, setTotalCount] = useState('')
  const [openCustomContentEditModal, setOpenCustomContentEditModal] = useState(false)
  const [openOverrideModal, setOpenOverrideModal] = useState(false)
  const [overrides, setOverrides] = useState(false)
  const [statutoryContent, setStatutoryContent] = useState('')
  const [rows, setRows] = useState([])
  const [redirectTo, setRedirectTo] = useState('')
  const [openRulesModal, setOpenRulesModal] = useState(false)
  const [rulesContent, setRulesContent] = useState('')

  const [modalType, setModalType] = useState('');

  const [sortColumn, setSortColumn] = useState('template_name');
  const [sortOrder, setSortOrder] = useState('asc');

  const [actionName, setActionName] = useState(ACTION_NAME.LEAVE_BENEFIT)
  const [isLoading, setIsLoading] = useState(false)

  const classes = useStyles();

  useEffect(() => {
    fetchData()
  }, [pageNumber, actionName, sortColumn, sortOrder]);

  const fetchData = () => {
    setIsLoading(true)
    $.ajax({
      method: 'GET',
      url: `${props.fetch_url}/?action_name=${actionName}&page_no=${pageNumber}&sort_by=${sortColumn}&sort_order=${sortOrder}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Expose-Headers": ["access-token", "expiry", "token-type", "uid", "client"],
      },
      success: (response) => {
        setRows(response.data)
        setTotalCount(response.total_count)
        setIsLoading(false)
      },
      error: (err) => {
        setIsLoading(false)
        console.log({ err })
      }
    })
  }

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  }


  const changePageNumber = (pageNumber) => {
    setPageNumber(pageNumber)
  }

  const handleModal = (type) => {
    setModalType(type);
  }

  const getContent = () => {
    switch (modalType) {
      case 'customContent':
        return <div style={{ padding: 10 }} dangerouslySetInnerHTML={{ __html: statutoryContent.value }} />
      case 'rules':
        return <div style={{ padding: 10 }} dangerouslySetInnerHTML={{ __html: rulesContent }}></div>
      case 'overrides':
        return (
          <div style={{ padding: 10 }}>
            {
              overrides.map((override, index) => {
                return <li key={index}>{override}</li>
              })
            }
          </div>
        );
      default:
        return null;
    }
  }

  const modalTitle = () => {
    switch (modalType) {
      case 'customContent':
        return 'Custom Content';
      case 'rules':
        return 'Rules';
      case 'overrides':
        return 'Overrides';
      default:
        return '';
    }
  }

  const modal = () => {
    return (
      <ModalBox
        show={openCustomContentEditModal || openRulesModal || openOverrideModal}
        hideModalInParent={() => {
          setOpenCustomContentEditModal(false);
          setOpenRulesModal(false);
          setOpenOverrideModal(false);
        }}
        title={modalTitle()}
        approveButtonText="Edit"
        approve={(toNavigate) => handleApprove(toNavigate)}
        decline={() => {
          setOpenCustomContentEditModal(false);
          setOpenRulesModal(false);
          setOpenOverrideModal(false);
        }}
      >
        {getContent()}
      </ModalBox>
    );
  }

  const templateHandler = (recordUrl) => {
    window.location.href = getFullURL(recordUrl)
  }

  function getFullURL (urlPath, toNavigate) {
    let loc = window.location;
    let url = "" + loc.protocol + "//" + loc.host + urlPath + (toNavigate || '');
    return url;
  }

  function handleApprove (toNavigate) {
    window.location.href = getFullURL(redirectTo, toNavigate)
  }

  const renderTemplateCategory = (row) => {
    const categoryCss = {
      fontSize: 48,
      textAlign: 'center',
      flex: 1
    }
    const category = row.template_category
    if (category === 'statutory') {
      return <i className={`material-icons ${category}`} style={categoryCss}>security</i>
    } else if (category === 'locked') {
      return <i className={`material-icons ${category}`} style={categoryCss}>lock</i>
    } else {
      return <i className={`material-icons ${category}`} style={categoryCss}>lock_open</i>
    }
  }



  const renderTableBody = () => {
    return (
      <>
        {rows.map((row, index) => {
          const benefitUpdatedDate = moment(row.updated_at).tz('America/Los_Angeles')
          const templateUpdatedDate = moment(row.template_updated_at).tz('America/Los_Angeles')
          return (
            <TableRow key={index} className={index % 2 === 0 ? classes.rowGrey : ''}>
              <TableCell className={classes.cell} component="th" scope="row">
                {renderTemplateCategory(row)}
              </TableCell>
              <TableCell className={classes.cell} component="th" scope="row" onClick={() => templateHandler(row.template_url)}>
                <u className={classes.content}>
                  {row.name}
                </u>
              </TableCell>
              <CustomContentHandler
                row={row}
                openCustomContentEditModal={openCustomContentEditModal}
                setOpenCustomContentEditModal={setOpenCustomContentEditModal}
                setStatutoryContent={setStatutoryContent}
                setRedirectTo={setRedirectTo}
                handleModal={handleModal}
              />
              { actionName === 'leave_benefits' && <CustomRulesHandler
                row={row}
                openRulesModal={openRulesModal}
                setOpenRulesModal={setOpenRulesModal}
                setRulesContent={setRulesContent}
                setRedirectTo={setRedirectTo}
                handleModal={handleModal}
              /> }
              <CustomOverridesHandler
                row={row}
                openOverrideModal={openOverrideModal}
                setOpenOverrideModal={setOpenOverrideModal}
                setOverrides={setOverrides}
                setRedirectTo={setRedirectTo}
                handleModal={handleModal}
              />
              <TableCell className={classes.cell}>
                <span className={`pull-left label status-label label-${ row.status }`}>{READABLE_STATUS[row.status]}</span>
              </TableCell>
              <TableCell className={classes.cell}>
                {benefitUpdatedDate.format(gon.default_date_format_string.toUpperCase())}
                <br />
                {benefitUpdatedDate.format("hh:mm A")}
              </TableCell>
              <TableCell className={classes.cell}>
                {templateUpdatedDate.format(gon.default_date_format_string.toUpperCase())}
                <br />
                {templateUpdatedDate.format("hh:mm A")}
              </TableCell>
            </TableRow>
          )
        })}
      </>
    )
  }

  const renderTableContainer = () => {
    return (
      <TableContainer>
        <Table aria-label="customized table">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={classes.cell}>
              { actionName === 'leave_benefits' ?
                <TableSortLabel
                  active={sortColumn === 'template_category'}
                  direction={sortOrder}
                  onClick={() => handleSort('template_category')}
                >
                  Category
                </TableSortLabel> :
                <></>
              }
              </TableCell>
              <TableCell className={classes.cell}>
                <TableSortLabel
                  active={sortColumn === 'template_name'}
                  direction={sortOrder}
                  onClick={() => handleSort('template_name')}
                >
                  Template
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.cell}>
                <TableSortLabel
                  active={sortColumn === 'custom_contents'}
                  direction={sortOrder}
                  onClick={() => handleSort('custom_contents')}
                >
                  Custom Content
                </TableSortLabel>
              </TableCell>
              { actionName === 'leave_benefits' &&
                <TableCell className={classes.cell}>
                  <TableSortLabel
                    active={sortColumn === 'rules'}
                    direction={sortOrder}
                    onClick={() => handleSort('rules')}
                  >
                    Rules
                  </TableSortLabel>
                </TableCell>
              }
              <TableCell className={classes.cell}>
                <TableSortLabel
                  active={sortColumn === 'overridden'}
                  direction={sortOrder}
                  onClick={() => handleSort('overridden')}
                >
                  Override
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.cell}>
                <TableSortLabel
                  active={sortColumn === 'status'}
                  direction={sortOrder}
                  onClick={() => handleSort('status')}
                >
                  Company Record Status
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.cell}>
                <TableSortLabel
                  active={sortColumn === 'updated_at'}
                  direction={sortOrder}
                  onClick={() => handleSort('updated_at')}
                >
                  {COMPANY_RECORD_HEADER_NAME[`${actionName}`]}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.cell}>
                <TableSortLabel
                  active={sortColumn === 'template_updated_at'}
                  direction={sortOrder}
                  onClick={() => handleSort('template_updated_at')}
                >
                  {TEMPLATE_RECORD_HEADER_NAME[`${actionName}`]}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              isLoading ? (
                <Box mt="10px" display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) :
                renderTableBody()
            }
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const renderPagination = () => {
    return (
      <Pagination
        totalItems={totalCount}
        changePageNumber={(pageNumber) => changePageNumber(pageNumber)}
        pageNumber={pageNumber}
        itemsPerPage={30}
      />
    )
  }

  const handleAction = (actionName) => {
    setActionName(actionName)
    setPageNumber(1)
  }

  const renderFilters = () => {
    return (
      <Box display={"flex"} gridGap={10} marginBottom="10px">
        <Button
          variant="contained"
          onClick={() => handleAction(ACTION_NAME.LEAVE_BENEFIT)}
          className={
            actionName === ACTION_NAME.LEAVE_BENEFIT ? classes.activeButton : classes.button
          }
        >
          Leave Benefit
        </Button>
        <Button
          variant="contained"
          onClick={() => handleAction(ACTION_NAME.PLANNING_GUIDE)}
          className={
            actionName === ACTION_NAME.PLANNING_GUIDE ? classes.activeButton : classes.button
          }
        >
          Planning Guide
        </Button>
        <Button
          variant="contained"
          onClick={() => handleAction(ACTION_NAME.POLICY_ITEMS)}
          className={
            actionName === ACTION_NAME.POLICY_ITEMS ? classes.activeButton : classes.button
          }
        >
          Task Item
        </Button>
        <Button
          variant="contained"
          onClick={() => handleAction(ACTION_NAME.MANAGER_TRAINING)}
          className={
            actionName === ACTION_NAME.MANAGER_TRAINING ? classes.activeButton : classes.button
          }
        >
          Manager Training
        </Button>
      </Box>
    )
  }

  return (
    <div className={classes.main}>
      <Box display="flex" justifyContent="space-between">
        {renderPagination()}
        <Box ml={"auto"}>
          {renderFilters()}
        </Box>
      </Box>
      {renderTableContainer()}
      {modal()}
    </div>
  );
}

const useStyles = makeStyles({
  cell: {
    fontSize: 14,
  },
  rowGrey: {
    background: '#f1f1f1'
  },
  content: {
    cursor: 'pointer'
  },
  main: {
    paddingTop: 70,
  },
  head: {
    backgroundColor: '#d6d6d6'
  },
  activeButton: {
    backgroundColor: "#123F6E",
    color: "white",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#123F6E",
      opacity: 0.85,
    },
  },
  button: {
    fontSize: 14,
  }

})