import React, { useState } from "react";
import FileUploadModal from "../file_uploads/FileUploadModal";
import FileUploadTableModal from "../file_uploads/FileUploadTableModal";
import PropTypes from "prop-types";
import FileUploadTable from "./FileUploadTable";

const ERROR_THEAD = ["Column Name", "Row Number", "Error details"];

function FileUploads(props) {
  let [state, setstate] = useState({
    showUploadModal: false,
    fileUploadLists: props.fileUploadedList,
    fileUpload: {},
    showTableModal: false
  })

  let formSubmitHandler = (data) => {
    setstate(prevState => ({
      ...state,
      fileUploadLists: [data, ...prevState.fileUploadLists],
      showUploadModal: false,
    }))
  }

  function deleteItemHandler(fileUploadId) {
    const fileUploadLists = state.fileUploadLists.filter(
      (fl) => fl.id != fileUploadId
    );
    setstate({...state, fileUploadLists: fileUploadLists });
  }

  function handleTableBodyClick(fileUploadId) {
    $.get(`${props.action}/${fileUploadId}`, (response) => {
      setstate({
        ...state,
        fileUpload: response.data,
        showTableModal: true,
      });
    });
  }

  function hideUploadModal() {
    setstate({...state, showUploadModal: false });
  }

  function showUploadModal() {
    setstate({...state, showUploadModal: true });
  }

  function hideTableModal() {
    setstate({...state, showTableModal: false });
  }

  return (
    <div className="company-uploads-container">
      <FileUploadTable
        tHead={props.headers}
        tBody={state.fileUploadLists}
        action={props.action}
        title={props.title}
        handleTableBodyClick={handleTableBodyClick}
        showUploadModal={showUploadModal}
        onDeleteItem={deleteItemHandler}
      />
      {state.showUploadModal && (
        <FileUploadModal
          action= {props.action}
          hideModal={hideUploadModal}
          onFormSubmit={formSubmitHandler}
          headerIncluded={props.headerIncluded}
          uploadTypeOptions={props.fileUploadTypes}
        />
      )}
      {state.showTableModal && (
        <FileUploadTableModal
          tBody={state.fileUpload.process_errors || {}}
          tHead={ERROR_THEAD}
          hideModal={hideTableModal}
          modalTitle={state.fileUpload.name}
        />
      )}
    </div>
  );
}

FileUploads.propTypes = {
  fileUploadedList: PropTypes.array,
  action: PropTypes.string,
  title: PropTypes.string,
  headers: PropTypes.array,
  headerIncluded: PropTypes.bool
};

export default FileUploads;
